.projetos{
    margin-bottom: 5%;
}

.imgProject {
    padding-top: 3%;
}

.projectDivider {
    width: 14%;
    margin: 0% 0% 0% 43%;
    border: 0;
    border-bottom: 3px solid #AACB73 !important;
    opacity: 1 !important;
}

@media (max-width: 1024px) {
    .divider {
        width: 22%;
        margin: 0% 0% 0% 39%;
    }
}

@media (max-width: 480px) {
    .projectDivider {
        width: 50%;
        margin: 0% 0% 0% 26%;
    }
}