a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.container, .container-fluid, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm{
  padding-right: var(--bs-gutter-x, 1.75rem);
  padding-left: var(--bs-gutter-x, 1.75rem);
}
.navbar{
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  text-align: center;
}

.option{
  border-bottom: 3px white solid;
}

.option:hover {
  /* border-bottom: 3px #809A6F solid; */
  border-bottom: 3px #AACB73 solid;
} 

.option:focus {
  border-bottom: 3px #AACB73 solid;
}

@media (max-width: 400px) {
  .logoposition {
    width: 250px;
  }
}

@media (max-width: 360px) {
  .logoposition {
    width: 200px;
  }
}

@media (max-width: 280px) {
  .logoposition {
    width: 166px;
  }
}
