.image{
    width: 30%;
    display: flex;
}

.description{
    width: 70%;
    display: flex;
}

.title{
    margin-bottom: 30px;
}

.price {
    margin-top: 30px;
    margin-left: 25%;
    margin-right: 25%;
    background-color: #AACB73;
    width: 50%;
    padding: 10px;
    /* border-radius: 10px; */
    text-align: center;
    border: 2px #AACB73 solid !important;
    border-radius: 5px;
}

.price:hover{
    background-color: white;
    border: 2px #AACB73 solid !important;
    transition: color 200ms cubic-bezier(0.785, 0.135, 0.15, 0.86), background-color 200ms cubic-bezier(0.785, 0.135, 0.15, 0.86), border-color 200ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.specifications{
    margin-top: 20px !important;
    margin-bottom: 20px;
}

.textBold{
    font-weight: bold;
}

.productContainer {
    margin-top: 20px !important;
    padding: 0% 1% !important;
}

.productInfo {
    max-width: 45% !important;
    padding-left: 2% !important;
}

.productImg {
    max-width: 55% !important;
}

@media (max-width: 480px) {
    .productImg {
        max-width: 100% !important;
    }

    .productInfo {
        max-width: 100% !important;
        padding-left: 16px !important;
    }
}

.dividerProduct{
        width: 100%;
        border: 0;
        border-bottom: 2px solid #AACB73 !important;
        opacity: 1 !important;
}

.stockInfoGreen{
    color: green;
    width: 0.7rem !important;
    height: 0.7rem !important;
}

.stockInfoRed{
    color: red;
    width: 0.7rem !important;
    height: 0.7rem !important;
}

.nameProduct{
    margin-bottom: 0px !important;
}

.contactsProdInfo{
    text-align: center;
}

.carousel .control-next.control-arrow:before {
    border-left: 8px solid #AACB73 !important;
}

.carousel .control-prev.control-arrow:before {
    border-right: 8px solid #AACB73 !important;
}

.carousel .control-dots .dot {
    background: #AACB73 !important;
}

.MuiTabs-indicator {
    background-color: #AACB73 !important ;
}