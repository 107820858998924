.sliderHome{
    height: 600px;
}

@media (max-width: 480px) {
    .sliderHome {
        height: 200px;
    }
}

.sliderDescription{
    display: block !important;
}