.footerContainer{
    background-color: rgb(189, 207, 157);
    min-height: 10%;
    width: 100%;
    text-align: center;
}

.icons{
    padding: 0% !important;
}

.footerCards{
    padding: 3% 10% 3% 10%;
}