.map{
    width: 100% !important;
}

.contactsInfo{
    margin-top: 50px;
}

.dividerContacts {
    width: 33%;
    /* color: rgb(194, 214, 154) !important; */
    margin: 0% 0% 0% 34%;
    border: 0;
    border-bottom: 3px solid #AACB73 !important;
    opacity: 1 !important;
}

.boxContacts{
    margin-top: 7%;
    margin-bottom: 8%;
    text-align: center;
}