.tabs{
    margin-top: 50px;
    padding: 0% 1%;
}

.colors{
    color: #727272 !important;
}

.MuiTabs-indicator {
    background-color: #AACB73 !important ;
}