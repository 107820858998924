.servicos{
    margin-top: 80px;
    padding-bottom: 80px;
}

.servicosCards {
    padding: 3% 10% 0% 10%
}

.divider {
    width: 22%;
    margin: 0% 0% 0% 39%;
    border: 0;
    border-bottom: 3px solid #AACB73 !important;
    opacity: 1 !important;
}

@media (max-width: 1024px) {
    .divider {
        width: 33%;
        margin: 0% 0% 0% 34%;
    }
}

@media (max-width: 480px) {
    .divider {
        width: 50%;
        margin: 0% 0% 0% 26%;
    }
}

.servicesTitle {
    padding: 0% 8% 0% 8%;
}

.link {
    color: green !important;
}

.link:hover {
    transition: 0.1s !important;
}

.linkBtn{
    background-color: rgb(194, 214, 154) !important;
    color: white !important;
}

.linkBtn:hover{
    background-color: white !important; 
    color: black !important;
    /* border: 1px !important; */
    border-color: rgb(194, 214, 154) !important;

}