.center {
    padding-left: 10%;
    padding-right: 10%;
}

.header {
    padding: 45px 0px 5px 0px;
}

.list {
    box-shadow: 0px 1px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 12%) !important;
}

.hrefColor {
    color: darkolivegreen !important;
}

.hrefColor:focus {
    color: aliceblue !important;
}

.paginaton {
    display: flex;
    justify-content: center;
    margin-top: 50px;
}

.test {
    padding-bottom: 5%;
}

.spaceFooter {
    padding-bottom: 10%;
}

.services {
    padding: 0% 3%;
}

.left {
    text-align: left;
}

.right {
    text-align: right;
}

::marker {
    color: rgb(134, 191, 32);
}

.dropdown-item:active {
    background-color: #AACB73 !important;
}

.input-form {
    border: solid 2px;
    border-color: rgb(241, 241, 241);
    width: 100%;
    height: 40px;
    background-color: rgb(241, 241, 241);
    border-radius: 5px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.input-form:focus-visible {
    outline: 2px solid #AACB73;
}

.input-form:hover {
    outline: 2px solid #AACB73;
}

.textarea-form {
    border: solid 2px;
    border-color: rgb(241, 241, 241);
    width: 100%;
    min-height: 100px;
    background-color: rgb(241, 241, 241);
    border-radius: 5px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.textarea-form:focus-visible {
    outline: 2px solid #AACB73;
}

.textarea-form:hover {
    outline: 2px solid #AACB73;
}

.form {
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 10%;
}

.spacingForm {
    margin-top: 3%;
}

.form-enviar {
    margin-top: 10px;
    margin-left: 30%;
    /* margin-right: 30%; */
    background-color: #AACB73;
    width: 40%;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
    border: 2px #AACB73 solid !important
}

.form-enviar:hover {
    background-color: white;
    border: 2px #AACB73 solid !important;
    transition: color 200ms cubic-bezier(0.785, 0.135, 0.15, 0.86), background-color 200ms cubic-bezier(0.785, 0.135, 0.15, 0.86), border-color 200ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.form-loading {
    width: 30px !important;
    height: 30px !important;
    color: #AACB73 !important;
    margin-left: 48%;
}

.div-loading {
    margin-top: 21px;
}

.productListLink:hover {
    color: #AACB73;
    transition: color 200ms cubic-bezier(0.785, 0.135, 0.15, 0.86), background-color 200ms cubic-bezier(0.785, 0.135, 0.15, 0.86), border-color 200ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.footerButton {
    background-color: rgb(189, 207, 157);
    width: 35%;
    padding: 5px;
    text-align: center;
    border: 1px aliceblue solid !important;
    border-radius: 5px;
}

.footerButton:hover {
    background-color: aliceblue;
    transition: color 200ms cubic-bezier(0.785, 0.135, 0.15, 0.86), background-color 200ms cubic-bezier(0.785, 0.135, 0.15, 0.86), border-color 200ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
}