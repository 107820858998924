.contentInfo{
    width: 100%;
}

.servicesWidth{
    padding-left: 20%;
    padding-right: 20%;
}

.dividerWidth{
    width: 90%;
    margin: 10px 0px 20px 0px !important;
    color: rgb(189, 207, 157) !important;
}

.serviceBtn {
    color: #727272 !important;
}

.serviceBtn:hover {
    /* color:rgb(189, 207, 157) !important; */
    color: green !important;
    text-decoration: underline !important;
    background-color: white !important;
}

.serviceBtnActive {
    /* color:rgb(189, 207, 157) !important; */
    color:green !important;
    text-decoration: underline !important;
    background-color: white !important;
}

.imgs{
    margin-top: 40px !important;
}

.imgssec2{
    margin-top: 10px !important;
}